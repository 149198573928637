#rsvp-container {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  padding: 100px 3vw;
}

#rsvp-form {
  display: flex;
  width: 100%;
}

label {
  font-family: GridReg;
  font-size: 18px;
  margin-bottom: 5px;
}

.rsvp-input {
  display: flex;
  width: 100%;
  padding: 12px 20px;
  border: 1px solid #8b949f;
  margin-bottom: 30px;
  font-family: inter;
  font-size: 18px;
}

#rsvp-header {
  margin: 0;
  display: flex;
  width: 100%;
  height: 71px;
  padding-bottom: 30px;
  justify-content: center;
}

#rsvp-submit {
  width: 100%;
  height: 74px;
  background-color: #00ba18;
  border: 1px solid #00ba18;
  color: #ffffff;
  cursor: pointer;
  border-radius: 4px;
  font-family: inter;
  font-size: 18px;
}

#button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  row-gap: 10px;
  margin-bottom: 30px;
}

.rsvp-button {
  width: 49%;
  height: 126px;
  background: #e9ecf3;
  border: 1px solid #dcdee0;
  cursor: pointer;
  font-family: GridReg;
  font-size: 18px;
}

.accept-active {
  background: #c5e3c9;
  border: 1px solid #00ba18;
  color: #00610d;
}

.decline-active {
  background: #ffd5d4;
  border: 1px solid #ff6962;
  color: #a1423e;
}

.rsvp-button.decline:hover {
  border: 1px solid #3c4c61;
  transition: 0.7s;
}

.rsvp-button:hover {
  border: 1px solid #3c4c61;
  transition: 0.7s;
}

.text {
  color: #8b949f;
  font-family: inter;
  font-size: 18px;
}

.rsvp-response {
  width: 100%;
}

.rsvp-response p {
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-family: GridReg;
}

@media only screen and (min-width: 769px) {
  #rsvp-container {
    padding: 100px 15vw;
  }
}
