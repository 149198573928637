.events-section {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 30vh;
}

.event-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 0 3vw;
    margin-bottom: 65px;
}

.events-date-wrapper {
    width: 15vw; 
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-bottom: 10px;
}

.events-date {
    font-family: GridReg;
    padding: 10px 0 0 0;
}

.events-content-wrapper {
    height: auto;
    padding: 0 3vw 0 0;
    width: 100%;
    text-align: left;
}

.event-info-line {
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.event-info-left {
    width: 20%;
    min-width: 83px;
    font-family: GridReg;
}

.event-info-right {
    width: 80%;
    padding-right: 0;
    font-family: inter;
    font-weight: 200;
}

#section-title-box-events {
    padding-left: 3vw;
  }

@media only screen and (min-width: 769px) {
    .event-container {
        flex-direction: row;
        padding: 0;
    }
    .events-content-wrapper {
        height: auto;
        padding: 0 15vw 0 0;
        width: 70vw;
        text-align: left;
    }
  }