.navbar {
    height: 8rem;
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.navbar-links {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 1rem;
}

.navbar a {
    font-size: 40px;
    font-family: SofiaBeta;
    font-weight: 400;
    padding: 0 15px;
    color: #000000;
    text-transform: uppercase;
    text-decoration: none;
    transition: ease-in-out 0.3s;
}

a.anchor {
  display: block;
  position: relative;
  visibility: hidden;
}

#anchor-events {
  top: -10px;
}

#anchor-info {
  top: -150px;
}

#anchor-stay {
  top: -150px;
}

#anchor-rsvp {
  top: -80px;
}

.navbar a:hover {
  color: #00BA18;
}

  @media screen and (max-width: 600px) {
    .navbar a {font-size: 30px; padding: 0 12px;}
  } 

  @media screen and (max-width: 500px) {
    .navbar a {font-size: 25px; padding: 0 10px;}
  } 

  @media screen and (max-width: 400px) {
    .navbar a {font-size: 20px; padding: 0 8px;}
  } 