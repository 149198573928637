.stay-with-us-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 3vw;
}

.stay-with-us {
  width: 100%;
  font-family: inter;
}

.stay-other-places {
  padding: 0 3vw;
  margin-bottom: 30vh;
}

.stay-other-places p {
  font-family: inter;
}

.how-to-book-btn {
    width: 100%;
    padding: 0;
    border: none;
    transition: ease-in-out 0.3s;
    background-color: white;
}

.how-to-book-btn:hover {
  color: #00BA18;
  cursor: pointer;
}

.how-to-book-btn div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.place-name-item {
  margin-bottom: 10px;
  transition: ease-in-out 0.3s;
}

.place-name-item:hover {
  color:#00BA18;
}

.place-name-item:hover .place-name-arrow {
  margin-right: -15px;
}

.place-to-stay-link div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.place-to-stay-link {
    text-decoration: none;
    color: black;
}

.arrow-down-closed{
    transition: transform 0.2s ease-in
}

.arrow-down-open{
    transform: rotate(-180deg);
    transition: transform 0.2s
}

.places-to-stay-wrapper p {
  font-family: GridReg;
  margin: 3px 0;
}

.link-arrow {
  transition: transform 0.5s
}

.link-arrow-active {
  transform: translateX(10px);
  transition: transform 0.5s
}

@media only screen and (min-width: 769px) {
  .stay-with-us-wrapper {
    flex-direction: row;
    padding: 0;
  }
  .stay-with-us{
    width: 85vw;
    padding: 0 15vw 0 0;
  }
  .stay-other-places {
    padding: 0 15vw;
  }
}