.info-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 3vw;
  margin-bottom: 30vh;
}

.info {
  width: 100%;
  font-family: inter;
}

.expandable-btn {
  width: 100%;
  padding: 0;
  border: none;
  font-family: GridReg;
  transition: ease-in-out 0.3s;
  cursor: pointer;
}

.expandable-btn:hover {
  color: #00BA18;
}

.expandable-btn div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

@media only screen and (min-width: 769px) {
  .info-wrapper {
    flex-direction: row;
    padding: 0;
  }
  .info {
    width: 85vw;
    padding: 0 15vw 0 0;
  }
}