.splash-container {
  margin-bottom: 30vh;
}

.splash-container p {
  font-family: inter;
  width: 100%;
  padding: 0 3vw;
  margin: 0;
}

@media only screen and (min-width: 769px) {
  .splash-container p {
    padding: 0 15vw;
  }
}