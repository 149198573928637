@font-face {
    font-family: 'GridReg';
    src: url('../../public/assets/fonts/GridReg/GridReg.eot'); /* IE9 Compat Modes */
    src: url('../../public/assets/fonts/GridReg/GridReg.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../public/assets/fonts/GridReg/GridReg.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../public/assets/fonts/GridReg/GridReg.woff') format('woff'), /* Pretty Modern Browsers */
         url('../../public/assets/fonts/GridReg/GridReg.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../public/assets/fonts/GridReg/GridReg.svg#GridReg') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'SofiaBeta';
    src: url('../../public/assets/fonts/SofiaBeta/SofiaBeta.eot'); /* IE9 Compat Modes */
    src: url('../../public/assets/fonts/SofiaBeta/SofiaBeta.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../public/assets/fonts/SofiaBeta/SofiaBeta.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../public/assets/fonts/SofiaBeta/SofiaBeta.woff') format('woff'), /* Pretty Modern Browsers */
         url('../../public/assets/fonts/SofiaBeta/SofiaBeta.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../public/assets/fonts/SofiaBeta/SofiaBeta.svg#SofiaBeta') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'inter';
    src: url('../../public/assets/fonts/inter/inter.eot'); /* IE9 Compat Modes */
    src: url('../../public/assets/fonts/inter/inter.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../../public/assets/fonts/inter/inter.woff2') format('woff2'), /* Super Modern Browsers */
         url('../../public/assets/fonts/inter/inter.woff') format('woff'), /* Pretty Modern Browsers */
         url('../../public/assets/fonts/inter/inter.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../../public/assets/fonts/inter/inter.svg#inter') format('svg'); /* Legacy iOS */
    font-weight: normal;
    font-style: normal;
}