@import "./Components/Events/events.css";
@import "./Components/Navbar/navbar.css";
@import "./Components/Header/header.css";
@import './Components/RSVP/rsvp.css';
@import './Components/Stay/stay.css';
@import './Components/HeroImage/heroimage.css';
@import './Components/Splash/splash.css';
@import './Components/Info/info.css';
@import './Components/fonts.css';

* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  font-size: 18px;
  color: #000;
  margin: 0;
    overflow-y: auto;
  scrollbar-gutter: stable;
  font-family: -apple-system, 'Arial', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button {
  color: black;
}

h1 {
  margin: 0;
  font-family: SofiaBeta;
  font-weight: 400;
  font-size: 64px;
  text-transform: uppercase;
}

.imgContainer {
  padding-bottom: 25px;
}

.iconContainer {
  height: 28px;
}

.iconContainer img {
  height: 100%;
  width: auto;
}

.section-title-box-desktop {
  width: 15vw;
  display: none;
  flex-direction: row;
  justify-content: center;
  padding-top: 20px;
}

.section-title-box-mobile {
  margin-bottom: 5px;
}

.slide-up, .slide-down {
  overflow:hidden;
}

.slide-up > div, .slide-down > div {
  height: 0;
  opacity: 0;
}

.slide-down > div {   
  height: auto;
  opacity: 1;         
} 

.slide-down {
  height: auto;
}

.slide-down-content {
  padding: 0 2vw;
  height: 300px;
}

.slide-down-content p {
  margin: 0;
}

.slide-down-content p a {
  color: #00BA18;
}

.slide-down-content-subheading {
  font-family: GridReg;
}

.slide-down-content-italicised {
  font-style: italic;
}

.slide-down-label {
  font-family: GridReg;
  font-size: 18px;
}

.arrow-down-closed{
  transition: transform 0.2s ease-in
}

.arrow-down-open{
  transform: rotate(-180deg);
  transition: transform 0.2s
}

@media only screen and (min-width: 769px) {
  .section-title-box-desktop {
    display: flex;
  }

  .section-title-box-mobile {
    display: none;
  }
}